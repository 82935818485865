import { INodeProps } from '@msdyn365-commerce-modules/utilities';
import { IImageData } from '@msdyn365-commerce/core';
import { ITealiumEventData, ITealiumPromotionData } from './mfrm-rest-assured-promise.props.autogenerated';

export const getPromoAnalyticsConfig = (image: IImageData | undefined, restAssuredPromiseAnchorTag: INodeProps | undefined, pagePath: string, paragraphText: string, tealiumPromotion: ITealiumPromotionData | undefined) => {
    // Use Configured Paragraph text as Promotion Name
    const promoName = tealiumPromotion?.promotion_name || paragraphText;
    // Use Configured Link as Promotion ID
    const promoID = tealiumPromotion?.promotion_id || restAssuredPromiseAnchorTag?.href || '';
    // Use Configured Image URL as default Creative
    const promoCreative = tealiumPromotion?.promotion_creative_version || image?.title || image?.altText || '';
    const promoPosition = tealiumPromotion?.promotion_position || undefined;

    return {
        eventLabel: `${promoName} from ${pagePath}`,
        promotionName: [promoName],
        promotionId: [promoID],
        promotionCreative: [promoCreative],
        promotionPosition: [promoPosition]
    };
};

export const getAnalyticsConfig = (pagePath: string, paragraphText: string, tealiumEvent: ITealiumEventData | undefined) => {
    return {
        eventName: tealiumEvent?.event || 'rap-module',
        eventCategory: tealiumEvent?.event_category || 'rest assured promise module',
        eventAction: tealiumEvent?.event_action || `${paragraphText} click`,
        eventLabel: tealiumEvent?.event_label || pagePath
    };
};